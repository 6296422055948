// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import 'core-js';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import { CookieSetting } from "cookiesetting-component";

import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      <CookieSetting 
           privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
           configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
           analytics={process.env.REACT_APP_ANALYTICS_PATH}
         />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
