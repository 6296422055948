import React from "react";
import StyledButton from "./styles";

export default function WorkshopButton({ showMobile, showDesktop }) {
  return (
    <StyledButton
      href="https://angelai.com/workshop/"
      target="_blank"
      rel="noreferrer"
      className={`ai-workshop ${showMobile ? "mobile" : ""} ${
        showDesktop ? "desktop" : ""
      }`}
    >
      <span>Sign up for free daily Ai workshops</span>
      <span>Click Here</span>
    </StyledButton>
  );
}
