const myCommissionsColumns = [1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75];

const tableHeaderName = [
  "Select Your Comp",
  "Today's Rate",

  //   "Conv 30",
  //   "FHA  30",
  //   "VA  30",
  //   "USDA  30",
];

const tableHeaderConv = ["CONVENTIONAL"];
const tableHeaderFha = ["GOVERNMENT"];

//  const addHundred = [
//   101, 101.25, 101.5, 101.75, 102, 102.25, 102.5, 102.75,103, 103.25
// ];
const addHundred = [101, 101.25, 101.5, 101.75, 102, 102.25];

const PAR_BASIS = 0.5;

const tableFormat = [
  //  { "my_Commission_is": [ 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75,3.0,3.25]},
  //  { "my_Commission_is": [ 1.5,3.0]},
  {
    my_Commission_is: [
      "100 bps",
      "125 bps",
      "150 bps",
      "175 bps",
      "200 bps",
      "225 bps",
    ],
  },
  { Conventional_30_Year: [] },
  { FHA_30_Year: [] },
  { VA_30_Year: [] },
  { USDA_30_Year: [] },
];

const videoIdArray = [
  {
    id: "rqJoteE-g6g",
  },
  {
    id: "1ozlxXmw_Gk", //1
  },
  {
    id: "8ER6EMAgiGA", //1
  },
  {
    id: "7Rps3dqYzFw", //1
  },
  {
    id: "qyi1DmdnQVA", //1
  },
  {
    id: "-iPrRUJhSSE", //1
  },
  {
    id: "-jDjGlbUNHk", //1
  },
  {
    id: "rSfPuIcchR8", //1m1
  },
  {
    id: "KUQejQRXKlU", //1m2
  },
  {
    id: "2hOmyRFQ1fU", //1m3
  },
  {
    id: "VMy_irzAlWI", //1n1
  },
  {
    id: "j-yMsvNfNC4", //1n2
  },
  {
    id: "zBUOEg1Miqs", //1n3
  },
  {
    id: "_do-FduChhk", //1n4
  },
  {
    id: "zNZyDw8hw7g", //1n5
  },
  {
    id: "P-Mp1FAjFvw", //1n6
  },

  {
    id: "E9b-zT06ZVQ", //1n8
  },
  {
    id: "oowzibd-3Zs", //1n9
  },
  {
    id: "cHRa9lJb_Jk", //1n10
  },
  {
    id: "UuaxMlqPROM", //1n11
  },
  {
    id: "lCDK3cgz0EQ", //1n12
  },
  {
    id: "hjXEZl0h8oA", //1n13
  },
  {
    id: "KTwAAAcTGps", //1n14
  },
  {
    id: "HK6xgXUzAKU", //1n15
  },
  {
    id: "gfCkBK8MeEc", //1n16
  },
  {
    id: "vHegPTMX0HU", //1n17
  },
  {
    id: "GwFW_YRb5EQ", //1n18
  },
  {
    id: "kls6tBSZY5M", //1n19
  },

  {
    id: "7SRmEDQ5M8s", //2
  },
  {
    id: "pAzzdHQdHcE", //3
  },
  {
    id: "GN8jvXiak1U", //13
  },
  {
    id: "_KtyxtkA8Ss", //14
  },
  {
    id: "d5ob-9_WbfU", //15
  },
  {
    id: "CRFffjNZ_Xw", //16
  },
  {
    // pending
    id: "3WgQLqc95h0", //17
  },
  {
    id: "y53IdKsPtZM", //18
  },
  {
    id: "NYBJjg9_qIg", //19
  },
  {
    id: "f19aZIIelE4", //20
  },
  {
    id: "HFKAJdKk7H8", //21
  },
  {
    id: "f52MxiH0ktE", //22
  },
  {
    id: "I08qYM0KnFM", //26
  },
  {
    id: "awleOacG_8c", //27
  },
  {
    id: "qVU7_rbdkYE", //28
  },
  {
    id: "NIDrwtOMb3Y", //29
  },
  {
    id: "Gn09PXSrvT0", //30
  },
];

const imagePath = "https://d2w24n4g34usfg.cloudfront.net/wholesale-websites";

const generateYtThumbnail = (idPassed) =>
  `${process.env.REACT_APP_PARTNER_CAROUSEL}${idPassed}.jpg`;

const generateYtVideoLink = (idPassed) =>
  `https://www.youtube.com/watch?v=${idPassed}&authuser=0`;

const dragElement = (elmnt, holderEle) => {
  // (myDIv, myDiveader is holding element for this for this)
  // console.log('DragFn:', elmnt)
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else if (holderEle && holderEle.length > 0) {
    /* if present, the header is where you move the DIV from:*/
    holderEle[0].onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
};
const getAssetUrl = (fileName) =>
  `${process.env.REACT_APP_ASSET_URL}${fileName}`;

function isMobileOrTablet() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isMobile = width <= 768 && height <= 1024; // Adjust the values as needed
  const isTablet = width > 768 && width <= 1024 && height > 1024; // Adjust the values as needed
  return isMobile || isTablet;
}
const getDeviceType = () => {
  // Check viewport width to determine device type
  const width = window.innerWidth;
  if (width < 768) {
    // Mobile
    return "mobile";
  } else if (width >= 768 && width < 992) {
    // Tablet
    return "tablet";
  } else {
    // Desktop
    return "desktop";
  }
};

const navScroll = (id) => {
  const deviceType = getDeviceType();
  let offsetHeight = 0;

  // Set offset height based on device type
  switch (deviceType) {
    case "mobile":
      offsetHeight = 50;
      break;
    case "tablet":
      offsetHeight = 80;
      break;
    case "desktop":
      offsetHeight = 100;
      break;
    default:
      offsetHeight = 0;
  }

  const element = document.getElementById(id);
  if (element) {
    const topPos = element.getBoundingClientRect().top + window.pageYOffset - offsetHeight;
    window.scrollTo({
      top: topPos,
      behavior: "smooth",
    });
  }
};

  
module.exports = {
  myCommissionsColumns,
  tableHeaderName,
  tableHeaderConv,
  tableHeaderFha,
  addHundred,
  PAR_BASIS,
  tableFormat,
  videoIdArray,
  imagePath,
  generateYtThumbnail,
  generateYtVideoLink,
  dragElement,
  getAssetUrl,
  isMobileOrTablet,
  navScroll,
};
