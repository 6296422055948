import styled from "styled-components";
import Button from "react-bootstrap/Button";

const StyledButton = styled(Button)`
  &.btn.ai-workshop.desktop {
    padding: 0;
    background-color: transparent;
    position: absolute;
    right: 4.5rem;
    border-radius: 30px;
    bottom: -1rem;
    display: flex;
    justify-content: center;
    border-color: transparent;
    width: fit-content;
  }

  &.btn.ai-workshop.mobile {
    display: none;
  }

  &.btn.ai-workshop span {
    border-radius: 30px 0 0 30px;
    padding: 0.15rem 1rem 0.2rem 1rem;
    background-color: rgb(247, 107, 28);
    display: inline-block;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 145%;
    text-align: center;
    color: #ffffff;
    transition: 0.2s background-color, 0.2s border;
  }
  &.btn.ai-workshop span:last-of-type {
    border-radius: 0 30px 30px 0;
    padding: 0.15rem 1rem 0.2rem 1rem;
    background-color: #2d388f;
  }

  &.btn.ai-workshop:hover span {
    background-color: rgb(255, 122, 45);
  }

  &.btn.ai-workshop:hover span:last-of-type {
    background-color: rgb(52, 63, 150);
  }
  &.btn.ai-workshop:where(:active, :focus, :focus:active) span {
    background-color: rgb(255, 122, 45);
  }
  &.btn.ai-workshop:where(:active, :focus, :focus:active) span:last-of-type {
    background-color: rgb(52, 63, 150);
  }
  &.btn.ai-workshop:where(:focus, :not(:focus:active)) {
    box-shadow: none;
  }
  @media (max-width: 991px) {
    &.btn.ai-workshop.desktop {
      display: none;
    }

    &.btn.ai-workshop.mobile {
      all: unset;
      padding: 0;
      background-color: transparent;
      position: absolute;
      border-radius: 30px;
      top: 6rem;
      display: flex;
      justify-content: center;
      border-color: transparent;
      width: fit-content;
    }
  }
  @media (max-width: 600px) {
    &.btn.ai-workshop.mobile {
      top: 8rem;
    }
  }
  @media (max-width: 475px) {
    &.btn.ai-workshop span {
      font-size: 14px;
      padding: 0.15rem 0.5rem 0.2rem 0.5rem;
    }
  }
  @media (max-width: 380px) {
    &.btn.ai-workshop span {
      font-size: 12px;
    }
  }
`;

export default StyledButton;
